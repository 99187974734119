import { useMediaQuery, useTheme } from "@mui/material";
import { useAuth } from "@sal/authentication.react";
import { type MenuItem } from "@sal/onevent-portal";
import { Outlet } from "react-router-dom";
import { MobileNavigation } from "../../components";
import { SecurityContext } from "../../constants";
import { HorizontalLayout } from "./HorizontalLayout";
import { VerticalLayout } from "./VerticalLayout";
import { LayoutProps } from ".";
import AppSettings from "../../appsettings";
import Box from "@mui/material/Box/Box";
import {
	AnalyticsOutlined,
	AttachMoney,
	Contacts,
	ContentPaste,
	Feed,
	GridViewRounded,
	LocalShipping,
	People,
	Phone,
	Public,
	WarningAmber
} from "@mui/icons-material";

type Auth = ReturnType<typeof useAuth>;

export default function BasePage() {
	const auth = useAuth();
	const theme = useTheme();
	const responsiveBreakpoint = useMediaQuery(theme.breakpoints.down("md"));

	/* Permissions; */
	const hasUserMenuPermission = (auth: Auth) => auth.userHasPermission(SecurityContext.Host, "User_Menu");
	const hasChangePasswordPermission = (auth: Auth) => auth.userHasPermission(SecurityContext.Host, "Change_Password");
	/* Permissions; */

	/* Menu Items; */
	const menuItems: Array<MenuItem> = [
		{
			label: "Dashboard",
			path: "/Dashboard",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_Consignment_Dashboard",
			icon: <GridViewRounded color="inherit" fontSize="inherit" />
		},
		{
			label: "Track",
			path: "/Track",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_TrackSearch",
			icon: <LocalShipping color="inherit" fontSize="inherit" />
		},
		{
			label: "Rates",
			path: "/Rates",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_RatesPage",
			icon: <AttachMoney color="inherit" fontSize="inherit" />
		},
		{
			label: "Consignment List",
			path: "/ConsignmentList",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_Consignment_List",
			icon: <ContentPaste color="inherit" fontSize="inherit" />
		},
		{
			label: "Users",
			path: "/Users",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_Users",
			icon: <People color="inherit" fontSize="inherit" />
		},
		{
			label: "Network Health",
			path: "/NetworkHealth",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_Network_Health",
			icon: <WarningAmber color="inherit" fontSize="inherit" />
		},
		{
			label: "Analytics",
			path: "/Analytics",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_Analytics",
			icon: <AnalyticsOutlined color="inherit" fontSize="inherit" />
		},
		{
			label: "Customers",
			path: "/Customers",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_Customer",
			icon: <Contacts color="inherit" fontSize="inherit" />
		},
		{
			label: "Locations",
			path: "/Locations",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_Location",
			icon: <Public color="inherit" fontSize="inherit" />
		},
		{
			label: "News & Resources",
			path: "https://www.booths.co.nz/bconnected/",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_News_And_Resources",
			icon: <Feed color="inherit" fontSize="inherit" />,
			openNewTab: true,
			isExternalURL: true
		},
		{
			label: "Contact",
			path: "https://www.booths.co.nz/our-locations",
			securityContext: "portal_Menu",
			securityContextPermission: "Web_Contact",
			icon: <Phone color="inherit" fontSize="inherit" />,
			openNewTab: true,
			isExternalURL: true
		}
	];
	/* Menu Items; */

	const Layout: React.ComponentType<LayoutProps> =
		AppSettings?.Layout?.Orientation === "Horizontal" ? HorizontalLayout : VerticalLayout;

	return (
		<Box display="flex" height="100vh" bgcolor="background.default">
			{responsiveBreakpoint ? (
				<Box flexGrow={1} minWidth={0} display="flex" flexDirection="column">
					{auth.isLoggedIn && (
						<Box flexGrow={0}>
							<MobileNavigation
								menuItems={menuItems}
								permissions={{
									userMenu: hasUserMenuPermission,
									changePassword: hasChangePasswordPermission
								}}
							/>
						</Box>
					)}
					<Box
						flexGrow={1}
						overflow="auto"
						sx={{
							scrollbarGutter: "stable"
						}}
					>
						<Outlet />
					</Box>
				</Box>
			) : (
				<Layout menuItems={menuItems} />
			)}
		</Box>
	);
}
