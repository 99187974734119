export const EMPTY_STRING = "";

export const noop = () => undefined;

export const defaultValue = <T, U>(value: T, fallback: U) =>
	value ?? fallback;

export const isEmpty = (value: string | null | undefined | number) => {
	return value === undefined || value === null || value === EMPTY_STRING;
}

export const addWeekDays = (value: string, add: number) => {
	if (Number.isNaN(Date.parse(value)) || Number.isNaN(add)) {
		return value;
	}
	const weekDays = [0, 1, 2, 3, 4];
	const weekDuration = 7;
	const date = new Date(value);
	const increment = weekDays.indexOf(date.getDay()) !== -1
		? add
		: add + weekDuration % date.getDay();
	date.setDate(date.getDate() + increment);
	return date.toISOString();
};
