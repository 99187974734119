import { DialogProps } from "@mui/material";
import { type OnEventApiResponse } from "@origin/platform-types";
import { type BaseConsignment } from "@sal/onevent-portal";
import { type UseQueryResult } from "@tanstack/react-query";
import { SubscriptionForm } from "./SubscriptionForm";

export interface SubscriptionDialogProps extends Omit<DialogProps, "open"> {
	query: UseQueryResult<OnEventApiResponse<BaseConsignment>, Error>;
	closeDialog: React.Dispatch<React.SetStateAction<boolean>>;
	isDialogOpen: boolean;
}
export function SubscriptionDialog({ closeDialog, isDialogOpen, query }: SubscriptionDialogProps) {
	return (
		<SubscriptionForm
			isDialogOpen={isDialogOpen}
			closeDialog={closeDialog}
			query={query}
		/>
	);
}
