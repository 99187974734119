import { useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";

export type PageHeaderProps = {
	title: React.ReactNode;
};
export function PageHeader({ title }: PageHeaderProps) {
	const theme = useTheme();
	const breakpoint = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<>
			{typeof title === "string" ? (
				<Typography fontSize={breakpoint ? "1.35rem" : "1.875rem"} lineHeight={1} fontWeight={500}>
					{title}
				</Typography>
			) : (
				<>{title}</>
			)}
		</>
	);
}
