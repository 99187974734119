import { Box, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup } from "@mui/material";
import { FormInput } from "@sal/onevent-portal";
import { Controller, useFormContext } from "react-hook-form";

export type RadioGroupFieldOption = {
	value: string;
	label: string;
	hasTextField?: boolean;
	customComponent?: React.ComponentType<{ name: string }>;
};

export type RadioGroupFieldVerticalProps = Omit<React.ComponentProps<typeof RadioGroup>, "name"> & {
	name: string;
	options: RadioGroupFieldOption[];
};
export function RadioGroupFieldVertical({ name, defaultValue, options, ...rest }: RadioGroupFieldVerticalProps) {
	const { control } = useFormContext();
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ field, fieldState: { error } }) => (
				<RadioGroup aria-label={name} {...field} {...rest}> {/* Removed row attribute */}
					<Grid container direction="column" spacing={2}> {/* Set direction to column for vertical layout */}
						{options.map((option) => (
							<Grid item xs={12} key={option.value}> {/* Full width for each item */}
								<Box>
									<FormControlLabel value={option.value} control={<Radio />} label={option.label} />
									{option.hasTextField && option.customComponent ? (
										<option.customComponent name={`${name}${option.value}`} />
									) : (
										option.hasTextField && <FormInput name={`${name}${option.value}`} />
									)}
								</Box>
							</Grid>
						))}
					</Grid>
					{error && (
						<FormHelperText error sx={{ ml: 0 }}>{error?.message}</FormHelperText>
					)}
				</RadioGroup>
			)}
		/>
	);
}
