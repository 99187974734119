import { memo, forwardRef } from "react";
import { GeoCoordinateInformationBanner } from "@sal/onevent-portal";
import { SxProps, Theme, Typography, useTheme } from "@mui/material";
import { NotificationsOutlined as NotificationsOutlinedIcon, Warning as WarningIcon } from "@mui/icons-material";

export const Banner = memo(forwardRef<HTMLDivElement>(function Banner(_, ref) {
	const theme = useTheme();
	return (
		<GeoCoordinateInformationBanner
			ref={ref}
			getIcon={(geoCoordinateInformationTypeSystemName: string) => {
				let icon;
				let sx: SxProps<Theme> = { fontSize: "0.85rem", marginRight: 2 };

				switch (geoCoordinateInformationTypeSystemName) {
					case "BoothsNotification":
						icon = <NotificationsOutlinedIcon />;
						break;
					case "GeneralEvent":
						icon = <WarningIcon />;
						sx = { ...sx, color: theme.palette.warning.main };
						break;
					case "SignificantEvent":
						icon = <WarningIcon />;
						sx = { ...sx, color: theme.palette.error.main };
						break;
				}

				return (
					<>
						{icon && (
							<Typography lineHeight="1" sx={sx}>
								{icon}
							</Typography>
						)}
					</>
				);
			}}
		/>
	);
}));
