import { Box, Grid, Paper, Stack, Typography, styled, useTheme } from "@mui/material";
import { ForgotPasswordForm } from "@sal/portal";
import booths_logo_connected from "../../public/images/Booths_B_Connected.png";

const StyledContainer = styled("div")(({ theme }) => ({
	[theme.breakpoints.down("sm")]: {
		width: 300
	},
	[theme.breakpoints.up("sm")]: {
		width: 350
	},
	[theme.breakpoints.up("lg")]: {
		width: 600
	}
}));

export function ForgotPasswordPage() {
	const theme = useTheme();

	return (
		<Grid
			container
			justifyContent="center"
			alignItems="center"
			style={{
				minHeight: "100vh",
				backgroundColor: "#000000"
			}}
		>
			<Stack
				spacing={12}
				useFlexGap
				flexWrap="wrap"
				direction="column"
				justifyContent="space-between"
				alignItems="center"
				sx={{ paddingTop: theme.spacing(2) }}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "left"
					}}
				>
					<img style={{ maxWidth: "55%" }} src={booths_logo_connected} alt="Logo" />
				</Box>
				<Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
					<StyledContainer>
						<Paper
							elevation={0}
							sx={{
								paddingX: theme.spacing(10),
								paddingY: theme.spacing(5),
								borderBottomRightRadius: 30,
								borderTopRightRadius: 0,
								borderBottomLeftRadius: 0,
								borderTopLeftRadius: 0
							}}
						>
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								spacing={4}
								style={{
									paddingLeft: "1rem"
								}}
							>
								<Grid item>
									<Typography
										variant="h5"
										align="center"
										style={{
											paddingTop: theme.spacing(2),
											paddingBottom: theme.spacing(8),
											paddingRight: theme.spacing(4),
											fontWeight: 700,
											fontFamily: "Montserrat, sans-serif"
										}}
									>
										Forgot Password
									</Typography>
								</Grid>
								<ForgotPasswordForm
									inputLabelStyles={{
										color: theme.palette.common.black,
										fontWeight: 700,
										fontFamily: "Montserrat"
									}}
								/>
							</Grid>
						</Paper>
					</StyledContainer>
				</Grid>
			</Stack>
		</Grid>
	);
}
